.grd {
    background: rgb(70, 125, 247);
    background: linear-gradient(0deg, rgba(70, 125, 247, 1) 0%, rgba(81, 192, 249, 1) 100%);
}

.grd2 {
    background: rgb(70, 125, 247);
    background: linear-gradient(0deg, rgba(70, 125, 247, 1) 0%, rgba(81, 192, 249, 1) 100%);
}

.grd3 {
    background: rgb(202, 221, 254);
    background: linear-gradient(201deg, rgba(202, 221, 254, 1) 0%, rgba(243, 247, 255, 1) 100%);
}



.letter-spacing {
    letter-spacing: 0.8rem;
}

.letter-spacing2 {
    letter-spacing: 0.4rem;
}

.letter-spacing3 {
    letter-spacing: 0.1rem;
}

.cardbg {
    background-color: #5243C2;
}

.fontclr {
    color: #D9ECFF;
}

.greengrd {
    background: rgb(37, 217, 217);
    background: linear-gradient(90deg, rgba(37, 217, 217, 1) 0%, rgba(37, 217, 217, 0.8676788586528361) 100%);
}

.yellowgrd {
    background: rgb(255, 204, 64);
    background: linear-gradient(90deg, rgba(255, 204, 64, 1) 0%, rgba(255, 204, 64, 0.8200598110337886) 100%);
}

.bluegrd {
    background: rgb(8, 152, 231);
    background: linear-gradient(90deg, rgba(8, 152, 231, 1) 0%, rgba(8, 152, 231, 0.8368665337228641) 100%);
}

.redgrd {
    background: rgb(255, 144, 102);
    background: linear-gradient(90deg, rgba(255, 144, 102, 1) 0%, rgba(255, 144, 102, 0.8060542087928921) 100%);
}

.dottedBorder {
    /* border: 2px dashed blue; */
    border-right: 2px dashed blue;
}

.circle {
    width: 25px;
    height: 25px;
    background-color: #5243C2;
    border-radius: 50%;
    position: absolute;
    right: -13px;
}

.rotateanimation {
    animation: rotate 12s infinite linear;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
        /* Start rotation from 0 degrees */
    }

    100% {
        transform: rotate(360deg);
        /* Rotate to 360 degrees */
    }
}

.rotateanimation2 {
    animation: rotate2 12s infinite linear;
}

@keyframes rotate2 {
    0% {
        transform: rotate(0deg);
        /* Start rotation from 0 degrees */
    }

    100% {
        transform: rotate(-360deg);
        /* Rotate to 360 degrees */
    }
}

.spinAnimation {
    animation: spin 0.6s linear;
}

@keyframes spin {
    0% {
        opacity: 0;
        transform: translateY(-130px);

    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

.footer {
    background-color: #467DF7;
}

/* For WebKit-based browsers (Chrome, Safari) */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* For Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

/* For Microsoft Edge */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    display: none;
}

.pagination {
    list-style: none;
    text-decoration: none;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
    gap: 10px;
    margin-top: 5rem;
}

.pagination .page-num {
    padding: 8px 15px;
    cursor: pointer;
    border-radius: 3px;
    text-decoration: none;
    color: black;
    font-weight: 400;
    /* transition: 1s; */
    border-radius: 20px;
    background-color: rgb(230, 233, 240);
}

.pagination .page-num:hover {
    background-color: rgb(69, 125, 247);
    color: white;
    transition: 0.8s;

}

.pagination .active {
    background-color: rgb(69, 125, 247);
    color: white;
    font-weight: 500;
}

.pagination .next {
    display: none;
}

.pagination .previous {
    display: none;
}

.transi {
    transition: 0.5s ease-in-out;
}

.scrollbar::-webkit-scrollbar {
    opacity: 0;
}

.scrollbar2::-webkit-scrollbar {
    width: 12px;
}

.scrollbar2::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
}

.scrollbar2::-webkit-scrollbar-corner {
    background-color: #f1f1f1;

}

.makeshadow {
    box-shadow: -2px 2.5px 0px rgb(160, 169, 192);
}