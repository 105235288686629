.moving {
    animation: movingAround 30s infinite linear;
}

@keyframes movingAround {
    0% {
        transform: translateY(0px) translateX(0px);
    }
    20% {
        transform: translateY(200px) translateX(200px);
    }
    40% {
        transform: translateY(200px) translateX(-200px);
    }
    60% {
        transform: translateY(-200px) translateX(200px);
    }
    80% {
        transform: translateY(-200px) translateX(-200px);
    }
    100%{
        transform: translateY(0px) translateX(0px) ;
    }
}
.moving1 {
    animation: movingAround1 30s infinite linear;
}

@keyframes movingAround1 {
    0% {
        transform: translateY(0px) translateX(0px);
    }
    20% {
        transform: translateY(-200px) translateX(-200px);
    }
    60% {
        transform: translateY(200px) translateX(-200px);
    }
    80% {
        transform: translateY(200px) translateX(200px);
    }
    100%{
        transform: translateY(0px) translateX(0px) ;
    }
}
.moving2 {
    animation: movingAround1 30s infinite linear;
}

@keyframes movingAround2 {
    0% {
        transform: translateY(0px) translateX(0px);
    }
    20% {
        transform: translateY(-200px) translateX(200px);
    }
    60% {
        transform: translateY(200px) translateX(-200px);
    }
    80% {
        transform: translateY(-200px) translateX(-200px);
    }
    100%{
        transform: translateY(0px) translateX(0px) ;
    }
}
.moving3 {
    animation: movingAround1 30s infinite linear;
}

@keyframes movingAround3 {
    0% {
        transform: translateY(0px) translateX(0px);
    }
    20% {
        transform: translateY(200px) translateX(-200px);
    }
    60% {
        transform: translateY(-200px) translateX(-200px);
    }
    80% {
        transform: translateY(200px) translateX(200px);
    }
    100%{
        transform: translateY(0px) translateX(0px) ;
    }
}
.welcomeanimation{
    animation: welcome 0.6s ease-in-out;
}
@keyframes welcome {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
    
}