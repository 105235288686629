@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-slide>div {
  margin: 0px 10px;
}

.slick-current .slider-item {
  margin-top: -50px;
  transition: 1s ease-in-out;
  filter: drop-shadow(3px 2px 10px black);
  animation: zoomin 2s linear forwards;
}

@keyframes zoomin {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.13)
  }

}

.slick-slide>div {
  transition: 1s linear;
}

.inter {
  font-family: "Inter", sans-serif;
}

.poppins {
  font-family: "Poppins", sans-serif;
}

.bgclr {
  background-color: rgba(217, 236, 255, 0.1);
}

.bgclr3 {
  background-color: rgb(217, 236, 255, 0.8);
}

.bgclr2 {
  background-color: rgba(125, 185, 205, 0.7);
  backdrop-filter: blur(10px);
}

.epilogue {
  font-family: "Epilogue", sans-serif;
}

.ubuntu {
  font-family: "Ubuntu", sans-serif;
}

.grd2 {
  background: rgb(70, 125, 247);
  background: linear-gradient(0deg, rgba(70, 125, 247, 1) 0%, rgba(81, 192, 249, 1) 100%);
}

.grd3 {
  background: rgb(70, 125, 247);
  background: linear-gradient(0deg, rgba(70, 125, 247, 1) 0%, rgba(81, 192, 249, 1) 100%);
}

.txtgrd {
  background: rgb(247, 102, 128);
  background: linear-gradient(201deg, rgba(247, 102, 128, 1) 0%, rgba(87, 0, 123, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg1 {
  background-color: #F5F5F5;
}

.bg2 {
  background-color: rgba(125, 185, 206, 0.4);
}

.bclr1 {
  border-color: #7DB9CE;
}

.tclr {
  color: #96bede;
}

.bclr2 {
  border-color: rgb(57, 153, 239);
}

.noarror::-webkit-inner-spin-button,
.noarror::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* App.css */
.app {
  width: 100vw;
  height: 100vh;
  perspective: 1000px;
}

.layer {
  width: 200px;
  height: 200px;
  position: relative;
  transform-style: preserve-3d;
  margin: auto;
  animation: rotate 6s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotateY(0deg) rotateX(0deg);
  }
  50%{
    transform: rotateY(-180deg) rotateX(-180deg);

  }
  100% {
    transform: rotateY(360deg) rotateX(360deg);
  }
}

.cube {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
}

.side {
  position: absolute;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid black;
}

.front { transform: translateZ(100px); }
.back { transform: rotateY(180deg) translateZ(100px); }
.left { transform: rotateY(-90deg) translateZ(100px); }
.right { transform: rotateY(90deg) translateZ(100px); }
.top { transform: rotateX(90deg) translateZ(100px); }
.bottom { transform: rotateX(-90deg) translateZ(100px); }
